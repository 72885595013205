import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Entre em contato conosco`}</h2>
    <p>{`Diga `}<a parentName="p" {...{
        "href": "mailto:connectiondayevento@gmail.com"
      }}>{`Oi`}</a>{` Ou fale conosco em nossas redes sociais: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/connectionday/"
      }}>{`Instagram`}</a>{`, `}<a parentName="p" {...{
        "href": "https://web.facebook.com/connectionDay/?_rdc=1&_rdr"
      }}>{`Facebook`}</a>{` e `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/conectados-comunidade/"
      }}>{`Linkedin`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      