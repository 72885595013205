import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`#ConnectionDay`}</h2>
    <ProjectCard title="Youtube" link="https://www.youtube.com/channel/UCzkh_dXfCwLTmx7W8GdBnOA" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Conheça nosso canal do youtube, nele temos lives e muito conteúdo interessante.
    </ProjectCard>
    <ProjectCard title="Palestras atuais" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Novos temas, palestras envolventes e conteúdo super atual.
    </ProjectCard>
    <ProjectCard title="Conhecimento" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Muito conhecimento a ser disseminado por meio de workshops, mesas de idéia, etc...
    </ProjectCard>
    <ProjectCard title="Participação da comunidade" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Convidamos toda a comunidade de TI para participar, sugerindo temas e atividades para serem apresentadas no evento.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      