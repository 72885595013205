import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Quem somos`}</h2>
    <p>{`O Connection Day é um evento sem fins lucrativos que busca conectar profissionais que atuam na área de TI e amantes de tecnologia. Temas atuais, palestras envolventes, networking e muito mais, gerando conhecimento e fortalecendo a comunidade de TI da nossa região. Desde 2018 trazendo palestras, workshops e lives que ajudam a comunidade de tecnologia mineira a evoluir cada vez mais.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      